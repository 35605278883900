import React, { useCallback, useMemo, VFC } from "react";

import { useMutation, useQuery, useQueryClient } from "react-query";

import { t } from "@lingui/macro";

import {
  Modal,
  ModalContent,
  ModalOverlay,
  ModalProps,
} from "@chakra-ui/modal";
import { useToast } from "@chakra-ui/react";

import {
  fetchUserStatus,
  linkWorkflowDef,
  unlinkWorkflowDef,
  updateProject,
} from "shared/api/client";
import { useGetProjectById } from "shared/lib/hooks/use-getProjectById";
import { useQueryParams } from "shared/lib/hooks/use-query-params";

import { BaseProject, TeamStatus } from "types";

import { FormCreateEditProject } from "../create-edit-form";
import { getAddedModels, getRemovedModels } from "../utils";

type Props = Omit<ModalProps, "children"> & {
  projectId: string;
};

export const ModalProjectEdit: VFC<Props> = ({ projectId, ...modalProps }) => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const {
    data: userStatusData,
    isLoading: isUserStatusLoading,
    isSuccess: isUserStatusSuccess,
  } = useQuery<TeamStatus>({
    queryKey: ["teamStatus"],
    queryFn: fetchUserStatus,
  });
  const { search, page, pageSize, sortBy, sortOrder } = useQueryParams();
  const project = useGetProjectById(projectId, modalProps.isOpen);
  const models = useMemo(() => {
    return (
      userStatusData?.models.sort((a, b) => a.name.localeCompare(b.name)) || []
    );
  }, [userStatusData]);

  const { isLoading, mutate } = useMutation(
    (project: BaseProject) => {
      return updateProject(projectId, project);
    },
    {
      onSuccess: async (res, payload) => {
        queryClient.refetchQueries([
          "projects",
          page,
          pageSize,
          sortBy,
          sortOrder,
          search,
        ]);
        const responseModels = res.data.workflowDefs || [];
        const requestedModels = payload.displayProperties?.models || [];

        const addedModels = getAddedModels(requestedModels, responseModels);
        const removedModels = getRemovedModels(requestedModels, responseModels);

        const isAllModelsSelected =
          payload.displayProperties?.models === "all" ||
          payload.displayProperties === null;

        if (
          isAllModelsSelected &&
          responseModels.length !== userStatusData?.models.length
        ) {
          const workflowDefIds =
            userStatusData?.models
              .filter((m) => !responseModels.find((r) => r.id === m.id))
              .map((m) => m.id) || [];
          await linkWorkflowsMutation.mutateAsync({
            projectId,
            workflowDefIds,
          });
        } else if (addedModels.length > 0 || removedModels.length > 0) {
          if (addedModels.length > 0) {
            await linkWorkflowsMutation.mutateAsync({
              projectId,
              workflowDefIds: addedModels,
            });
          }
          if (removedModels.length > 0) {
            await unlinkWorkflowsMutation.mutateAsync({
              projectId,
              workflowDefIds: removedModels,
            });
          }
        } else {
          await queryClient.invalidateQueries(["project", res.data.id]);
          modalProps.onClose();
        }
      },
      onError: (err) => {
        console.error(err);
        toast({
          title: "Error edit project",
          description: t`Error edit project`,
          position: "bottom",
          status: "error",
          isClosable: true,
        });
      },
    }
  );

  const linkWorkflowsMutation = useMutation(linkWorkflowDef, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(["project", project?.data?.id]);
      modalProps.onClose();
    },
    onError: (err) => {
      console.error(err);
    },
  });

  const unlinkWorkflowsMutation = useMutation(unlinkWorkflowDef, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(["project", project?.data?.id]);
      modalProps.onClose();
    },
    onError: (err) => {
      console.error(err);
    },
  });

  const handleSubmit = useCallback(
    (project: BaseProject) => {
      const payload = {
        ...project,
        displayProperties: {
          ...project.displayProperties,
          models:
            project.displayProperties?.models?.length === models.length
              ? "all"
              : project.displayProperties?.models,
        },
      };
      mutate(payload);
    },
    [mutate]
  );
  return (
    <Modal isCentered size="4xl" {...modalProps}>
      <ModalOverlay />
      <ModalContent borderRadius="ui-base">
        <FormCreateEditProject
          onSubmit={handleSubmit}
          isLoading={isLoading}
          models={models}
          isUserStatusLoading={isUserStatusLoading || project.isLoading}
          isUserStatusSuccess={isUserStatusSuccess || project.isSuccess}
          isWorkflowLinkUnlinkLoading={
            linkWorkflowsMutation.isLoading || unlinkWorkflowsMutation.isLoading
          }
          isEdit
          baseProject={project.data}
        />
      </ModalContent>
    </Modal>
  );
};
